import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Button, Divider, Drawer, Hidden, Typography } from '@mui/material';
import CreditScoreIcon from '@mui/icons-material/CreditScoreOutlined';
import FileIcon from '@mui/icons-material/DescriptionOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutline';
import GroupWorkIcon from '@mui/icons-material/GroupWorkOutlined';
import SettingIcon from '@mui/icons-material/SettingsOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptLongOutlined';
import SellIcon from '@mui/icons-material/SellOutlined';
import useAuth from 'hooks/useAuth';
import NavSection, { getItems, NavItemModel } from '../NavSection';
import Scrollbar from '../Scrollbar';
import { hasValue } from '../inputs/inputHelpers';
import ContactUsButton from './ContactUsButton';
import { appPermissions, localRoutes, remoteRoutes, rootAdminUserRoles } from '../../constants';
import { appSettings, remoteConfigData } from '../../config';
import LumenLogo from '../../logos/LumenLogo';
import { toTitleCase, trimString } from "../../utils/stringHelpers";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

type Section = {
  title: string,
  items: NavItemModel[]
};

const sections:Section[] = [
  {
    title: 'Products',
    items: [
      {
        title: 'Credit Checks',
        icon: <CreditScoreIcon fontSize="small" />,
        path: localRoutes.creditChecks,
        roles: [appPermissions.CREDIT_CHECK_TRIGGER, appPermissions.VIEW_CREDIT_CHECKS]
      },
      {
        title: 'Data Submissions',
        icon: <FileIcon fontSize="small" />,
        path: localRoutes.dataSubmissions,
        roles: [appPermissions.VIEW_DATA_SUBMISSIONS]
      }
    ]
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Staff',
        icon: <PeopleIcon fontSize="small" />,
        path: '/staff',
        roles: [appPermissions.PARTNER_MEMBERS_EDIT, appPermissions.PARTNER_MEMBERS_VIEW]
      },
      {
        title: 'Partners',
        icon: <GroupWorkIcon fontSize="small" />,
        path: '/partners',
        roles: [appPermissions.PARTNERS_EDIT, appPermissions.PARTNERS_VIEW],
        requiresPrimaryUser: true
      },
      {
        title: 'Settings',
        icon: <SettingIcon fontSize="small" />,
        path: '/settings',
        roles: [appPermissions.PARTNER_SETTINGS_EDIT, appPermissions.PARTNER_SETTINGS_VIEW],
      },
      {
        title: 'Invoices',
        icon: <ReceiptIcon fontSize="small"/>,
        path: '/invoices',
        roles: [appPermissions.VIEW_INVOICES, ...rootAdminUserRoles],
        requiresAllRoles: true,
        requiresPrimaryUser: true
      },
      {
        title: 'Price Bands',
        icon: <SellIcon fontSize="small"/>,
        path: localRoutes.priceBands,
        roles: [appPermissions.VIEW_PRICE_BANDS, ...rootAdminUserRoles],
        requiresAllRoles: true,
        requiresPrimaryUser: true
      }
    ]
  },
];

const DashboardSidebar: FC<React.PropsWithChildren<DashboardSidebarProps>> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const sectionsData = useMemo<Section[]>(() => sections
    .map((it) => ({ ...it, items: getItems(it.items, user) }))
    .filter((it) => hasValue(it.items)), [user, sections]);

  const content = (
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}
        >
            <Scrollbar options={{ suppressScrollX: true }}>
                <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%'
                    }}
                >
                    <Hidden lgDown>
                        <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                              p: 2
                            }}
                        >
                            <RouterLink to="/">
                                <LumenLogo
                                    sx={{
                                      width: 50,
                                      height: 50,
                                    }}
                                />
                            </RouterLink>
                            <Box
                                sx={{
                                  ml: 1
                                }}
                            >
                                <Typography
                                    color="text.primary"
                                    variant="h6"
                                >
                                    {appSettings.appName}
                                </Typography>
                            </Box>
                        </Box>
                    </Hidden>
                    <Box sx={{ px: 2, pb: 2 }}>
                        <Box
                            sx={{
                              alignItems: 'center',
                              backgroundColor: 'background.default',
                              borderRadius: 1,
                              display: 'flex',
                              overflow: 'auto',
                              p: 2
                            }}
                        >
                            <Box>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    {toTitleCase(user.name)}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {trimString(user.email, 20, '...')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Divider/>
                    <Box sx={{ p: 2 }}>
                        {sectionsData.map((section) => (
                            <NavSection
                                key={section.title}
                                pathname={location.pathname}
                                sx={{
                                  '& + &': {
                                    mt: 3
                                  }
                                }}
                                {...section}
                            />
                        ))}
                    </Box>
                    <Box sx={{
                      marginTop: 'auto',
                      width: '100%'
                    }}
                    >
                        <Divider/>
                        <Box
                            sx={{
                              px: 2,
                              py: 3
                            }}
                        >
                            <Button
                                color="primary"
                                fullWidth
                                sx={{
                                  mt: 2,
                                  justifyContent: 'flex-start',
                                  textTransform: 'none',
                                }}
                                href={remoteConfigData.feedbackSurveyUrl()}
                                target="_blank"
                                variant="text"
                            >
                                Give us feedback
                            </Button>
                            <ContactUsButton title="Contact us"/>
                            <Button
                                color="primary"
                                fullWidth
                                sx={{ mt: 2 }}
                                href={remoteRoutes.apiDocumentation}
                                target="_blank"
                                variant="contained"
                            >
                                API Documentation
                            </Button>

                        </Box>
                    </Box>
                </Box>
            </Scrollbar>
        </Box>
  );

  return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    PaperProps={{
                      sx: {
                        backgroundColor: 'background.paper',
                        width: 280
                      }
                    }}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    open
                    PaperProps={{
                      sx: {
                        backgroundColor: 'background.paper',
                        height: '100%',
                        width: 280,
                        border: 'none'
                      }
                    }}
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
  );
};

export default DashboardSidebar;
